import {BrowserRouter, Route, Switch, NavLink} from "react-router-dom";
//On Boarding
import Login from "../components/OnBoarding/Login";
import Register from "../components/OnBoarding/Register";
//Main Page
import HomePage from "../components/MainPage";
import Approval from "../components/MainPage/Approval";
//Admin Panel
// import Dashboard from "../components/MainPage/dashboard";
import AdminLogin from "../components/AdminPanel/Login";
import Properties from "../components/MainPage/Properties";
import PropertyDetails from "../components/MainPage/Properties/Property";
import PostProperty from "../components/MainPage/PostProperty";
import ContactUs from "../components/MainPage/ContactUs";
import ProjectGallery from "../components/MainPage/Gallery";


const PrivateRoute = ({component:Component, ...rest}) => {
    const sessionStorage = JSON.parse(window.sessionStorage.getItem('auth'))
    const auth = sessionStorage === null ? {isUserLoggedIn: false} : sessionStorage
    return(
        <Route {...rest} render={ props =>
            (auth.isUserLoggedIn) ?
                <Component {...props} />
                :
                <NavLink to="/login" />
        }/>
    );
}

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Switch>
                {/*Main Page*/}
                {/*#FE1620*/}
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/properties" component={Properties}/>
                <Route exact path="/approval-process" component={Approval}/>
                <Route exact path="/post-property" component={PostProperty}/>
                <Route exact path="/gallery" component={ProjectGallery}/>
                <Route exact path="/contact-us" component={ContactUs}/>

                {/*OnBoarding*/}
                <Route exact path="/register" component={Register}/>
                <Route exact path="/login" component={Login}/>

                {/*Dashboard*/}
                <Route exact path="/org/login" component={AdminLogin}/>
                {/* <PrivateRoute exact path="/hub/dashboard" component={Dashboard}/> */}
                {/* <PrivateRoute exact path="/hub/dashboard/customers" component={Customers} /> */}

                {/*Sub Pages*/}
                <Route exact path="/properties/:propertyId" component={(propertyId) => <PropertyDetails property={propertyId} />}/>
     
            </Switch>
        </BrowserRouter>
    )
}

export default AppRouter;

import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import {Grid, Paper, TextField, Button, Typography} from '@mui/material';
import {useDispatch} from "react-redux";
import actions from "../../../redux/auth/actions";
import {makeStyles} from "@mui/styles";
import {history} from "../../../redux/store";
import Navbar from '../../Header/Navbar';


const Login = () => {
    const [email, setEmailAddress] = useState(null);
    const [password, setPassword] = useState(null);
    const dispatch = useDispatch();
    const handleSubmit = () =>{
        dispatch({ type: actions.LOGIN_START, loginData:{email: email, password: password}});
        setInterval(() => history.push('/dashboard'), 1000);
    }
    const classes = customStyles();
    return(
        <div className={classes.loginRoot}>
            <Navbar />
            {/* <AppBar position="static" className={classes.navBar}>
                <Toolbar>
                    <Typography variant="h5" className={clsx(classes.title)}>
                        Admin Login
                    </Typography>                    
                </Toolbar>
            </AppBar>  */}
            <Grid container style={{height: '90%'}}>
                <Grid item className={clsx(classes.gridSize, classes.fullWidth)}>
                    <Paper className={classes.cardSize}>
                        <Grid align='center'>
                            <Typography variant="h5" style={{color: '#0c5aa5', fontWeight: 400, marginBottom: '20px', marginTop: 0}}>
                                Welcome to VIP City Properties!
                            </Typography>
                        </Grid>
                        <TextField
                            label='Email Address'
                            placeholder='Enter email address'
                            fullWidth
                            required
                            className={classes.margin8}
                            onChange={(e) => setEmailAddress(e.target.value)}
                        />
                        <TextField
                            label='Password'
                            placeholder='Enter password'
                            type='password'
                            fullWidth
                            required
                            className={classes.margin8}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type='submit'
                            color='primary'
                            variant="contained"
                            fullWidth
                            className={classes.postBtn}
                            onClick={() => handleSubmit()}
                        >
                            Login
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default Login

const customStyles = makeStyles({
    navBar: {
        backgroundColor: "#26d48c",
        boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 4%)",
    },
    title: {
        flexGrow: 1,
        color: "#FFF",
        fontWeight: 400,
    },
    margin8: {
        margin: "10px 0",
    },
    fullWidth: {
        width: "100vw"
    },
    postBtn: {
        minWidth: 120,
        padding: "8px 20px",
        margin: "10px 0px",
        boxShadow: "none",
        borderRadius: 10,
        color: "#f7f7f7",
        fontWeight: 600,

        '&:hover': {
            boxShadow: "none",
        },
    },
    loginRoot: {
        flexGrow: 1,
        height: "100vh",
        backgroundColor: '#F7F7F7',
    },
    gridSize: {
        display: "flex",
        justifyContent: "center",
    },
    cardSize: {
        maxWidth: "450px",
        padding: 40,
        margin: "auto",
        borderRadius: 10,
        boxShadow: "none",
    },
})

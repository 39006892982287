import Typography from "@mui/material/Typography";
import Slider from 'react-slick';
import {makeStyles} from '@mui/styles';
import {plImg} from '../../../helpers/constant';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const PopularLocalities = () => {
    const settings = {
        dots: true,
        arrows:true,
        autoplay: true,
        autoplaySpeed: 2500,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 775,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const classes = customStyles();
    return (
        <div style={{backgroundColor: "#F7F7F7", padding: 20}}>
            <Typography variant='h4' style={{color: '#0c5aa5', padding: '1.5rem', textAlign: 'center', fontWeight: 500}}>Popular Localities</Typography>
            <div style={{padding: '1rem 2rem'}}>
                <Slider {...settings}>
                    {plImg.map(ph => 
                        <div>
                            <img style={{padding: '20px', width: 375, height: 375}} src={ph.url} alt='drive image'/>
                            <Typography variant='h5' style={{color: '#08A04B', padding: '1.5rem', textAlign: 'center', fontWeight: 500}}>{ph.name}</Typography>
                        </div>)}
                </Slider>
            </div>
        </div>
    );
};

export default PopularLocalities;

const customStyles = makeStyles({
    flexCenter: {
        display: "flex",
        justifyContent: "center",
        justifyItems: 'center',
        padding: "2rem",
    },
})

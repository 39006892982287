const actions = {
    GET_ACCOUNTS_START: 'GET_ACCOUNTS_START',
    GET_ACCOUNTS_SUCCESS: 'GET_ACCOUNTS_SUCCESS',
    GET_ACCOUNTS_FAILURE: 'GET_ACCOUNTS_FAILURE',
    CREATE_ACCOUNTS_START: 'CREATE_ACCOUNTS_START',
    CREATE_ACCOUNTS_SUCCESS: 'CREATE_ACCOUNTS_SUCCESS',
    CREATE_ACCOUNTS_FAILURE: 'CREATE_ACCOUNTS_FAILURE',
    DELETE_ACCOUNTS_START: 'DELETE_ACCOUNTS_START',
    DELETE_ACCOUNTS_SUCCESS: 'DELETE_ACCOUNTS_SUCCESS',
    DELETE_ACCOUNTS_FAILURE: 'DELETE_ACCOUNTS_FAILURE',
    GET_ACCOUNT_DETAILS_START: 'GET_ACCOUNT_DETAILS_START',
    GET_ACCOUNT_DETAILS_SUCCESS: 'GET_ACCOUNT_DETAILS_SUCCESS',
    GET_ACCOUNT_DETAILS_FAILURE: 'GET_ACCOUNT_DETAILS_FAILURE',
    GET_DOCS_START: 'GET_DOCS_START',
    GET_DOCS_SUCCESS: 'GET_DOCS_SUCCESS',
    GET_DOCS_FAILURE: 'GET_DOCS_FAILURE',
    POST_USER_BASIC_DETAILS_START: 'POST_USER_BASIC_DETAILS_START',
    POST_USER_BASIC_DETAILS_SUCCESS: 'POST_USER_BASIC_DETAILS_SUCCESS',
    POST_USER_BASIC_DETAILS_FAILURE: 'POST_USER_BASIC_DETAILS_FAILURE',
};

export default actions;

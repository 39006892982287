import React, {useState} from 'react';
import {TextField, Grid, FormControl, InputLabel, MenuItem, Button, Select} from '@mui/material';
import clsx from 'clsx';
import customStyles from '../../../assets/styles/customStyles'
import {useDispatch} from 'react-redux';
import actions from '../../../redux/main/post-property/actions';


export default function PostPropertyDetails() {

    const [title, setTitle] = useState(null);
    const [projectName, setProjectName] = React.useState(null);
    const [price, setPrice] = React.useState(null);
    const [propertyType, setPropertyType] = React.useState('Lands & Plots');
    const [type, setType] = React.useState('Sale');
    const [listedBy, setListedBy] = React.useState(null);
    const [facing, setFacing] = React.useState(null);
    const [area, setArea] = React.useState(null);
    const [length, setLength] = React.useState(null);
    const [breadth, setBreadth] = React.useState(null);
    const [description, setDescription] = React.useState(null);

    const dispatch = useDispatch();
    const handleSubmit = () =>{
        dispatch({
            type: actions.POST_USER_BASIC_DETAILS_START,
            basicDetails: {
                title: title,
                projectName: projectName,
                price: price,
                propertyType: propertyType,
                type: type,
                listedBy: listedBy,
                facing: facing,
                area: area,
                length: length,
                breadth: breadth,
                description: description,
            }});
    }

    const classes = customStyles()

    return (
        <Grid container className={clsx(classes.padding20)}>
            <Grid item xs={6} className={classes.padding16a}>
                <TextField
                    label='Ad Title'
                    placeholder=' Ad Title'
                    className={classes.margin8}
                    fullWidth
                    required
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                    label='Project Name'
                    placeholder='Project Name'
                    className={classes.margin8}
                    fullWidth
                    required
                    onChange={(e) => setProjectName(e.target.value)}
                />
                <TextField
                    label='Price'
                    placeholder='Price'
                    className={classes.margin8}
                    fullWidth
                    required
                    onChange={(e) => setPrice(e.target.value)}
                />
                <FormControl fullWidth className={classes.margin8}>
                    <InputLabel id='property-type'>Property Type</InputLabel>
                    <Select
                        id='property-type'
                        value={propertyType}
                        label='Property Type'
                        onChange={(e) => setPropertyType(e.target.value)}
                    >
                        <MenuItem value={'Lands & Plots'}>Lands & Plots</MenuItem>
                        <MenuItem value={'Houses & Apartments'}>Houses & Apartments</MenuItem>
                        <MenuItem value={'Commercial Offices & Shops'}>Commercial Offices & Shops</MenuItem>
                        <MenuItem value={'PG & Guest Houses'}>PG & Guest Houses</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth className={classes.margin8}>
                    <InputLabel id='type'>Type</InputLabel>
                    <Select
                        id='type'
                        value={type}
                        label='Type'
                        onChange={(e) => setType(e.target.value)}
                    >
                        <MenuItem value={'Rent'}>For Rent</MenuItem>
                        <MenuItem value={'Sale'}>For Sale</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth className={classes.margin8}>
                    <InputLabel id='listedBy'>Listed by</InputLabel>
                    <Select
                        id='listedBy'
                        value={listedBy}
                        label='Listed by'
                        onChange={(e) => setListedBy(e.target.value)}
                    >
                        <MenuItem value={'Owner'}>Owner</MenuItem>
                        <MenuItem value={'Dealer'}>Dealer</MenuItem>
                        <MenuItem value={'Builder'}>Builder</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6} className={classes.padding16a}>
            <FormControl fullWidth className={classes.margin8}>
                    <InputLabel id='facing'>Facing</InputLabel>
                    <Select
                        id='facing'
                        value={facing}
                        label='Facing'
                        onChange={(e) => setFacing(e.target.value)}
                    >
                        <MenuItem value={'East'}>East</MenuItem>
                        <MenuItem value={'North'}>North</MenuItem>
                        <MenuItem value={'North-East'}>North-East</MenuItem>
                        <MenuItem value={'North-West'}>North-West</MenuItem>
                        <MenuItem value={'South'}>South</MenuItem>
                        <MenuItem value={'South-East'}>South-East</MenuItem>
                        <MenuItem value={'South-West'}>South-West</MenuItem>
                        <MenuItem value={'West'}>West</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label='Area'
                    placeholder='Area'
                    className={classes.margin8}
                    fullWidth
                    required
                    onChange={(e) => setArea(e.target.value)}
                />
                <TextField
                    label='Length'
                    placeholder='Length'
                    className={classes.margin8}
                    fullWidth
                    required
                    onChange={(e) => setLength(e.target.value)}
                />
                <TextField
                    label='Breadth'
                    placeholder='Breadth'
                    className={classes.margin8}
                    fullWidth
                    required
                    onChange={(e) => setBreadth(e.target.value)}
                />
                <TextField
                    label='Description'
                    placeholder='Description'
                    className={classes.margin8}
                    fullWidth
                    required
                    onChange={(e) => setDescription(e.target.value)}
                />
                
            </Grid>
            <Button
                type='submit'
                color='primary'
                variant='contained'
                style={{
                    marginLeft: 15,
                    marginBottom: 20
                }}
                className={classes.postBtn}
                onClick={() => handleSubmit()}
            >
                Save
            </Button>
        </Grid>
    )
}

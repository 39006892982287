import React from 'react';
import {Grid, Paper, Typography} from "@mui/material";
import {makeStyles} from '@mui/styles';
import clsx from "clsx";

const DevelopmentWorks = () => {
    const classes = customStyles();
    return(
        <Grid style={{padding: 25}}>
            <Typography variant="h4" style={{color: "#0c5aa5", padding: "1.5rem", textAlign: "center", fontWeight: 500}}>VIP City Amenities</Typography>
            <Grid container style={{padding: 20}}>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper style={{margin: '1.5rem', padding: '1rem', color:'#1842B6', boxShadow: "0 0 60px rgba(0, 0, 0, .2)"}}>
                        <Typography variant="h5" align="center" className={classes.padding8}>
                            DTCP & RERA Approval
                        </Typography>
                    </Paper>
                </Grid>
                
                <Grid item xs={12} md={6} lg={3}>
                    <Paper style={{margin: '1.5rem', padding: '1rem', color:'#1842B6', boxShadow: "0 0 60px rgba(0, 0, 0, .2)"}}>
                        <Typography variant="h5" align="center" className={classes.padding8}>
                            Tar Road Facility
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper style={{margin: '1.5rem', padding: '1rem', color:'#1842B6', boxShadow: "0 0 60px rgba(0, 0, 0, .2)"}}>
                        <Typography variant="h5" align="center" className={classes.padding8}>
                            Street Light
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper style={{margin: '1.5rem', padding: '1rem', color:'#1842B6', boxShadow: "0 0 60px rgba(0, 0, 0, .2)"}}>
                        <Typography variant="h5" align="center" className={classes.padding8}>
                            Water Facility
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper style={{margin: '1.5rem', padding: '1rem', color:'#1842B6', boxShadow: "0 0 60px rgba(0, 0, 0, .2)"}}>
                        <Typography variant="h5" align="center" className={classes.padding8}>
                            Drainage Facility
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper style={{margin: '1.5rem', padding: '1rem', color:'#1842B6', boxShadow: "0 0 60px rgba(0, 0, 0, .2)"}}>
                        <Typography variant="h5" align="center" className={classes.padding8}>
                            Electricity Facility
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper style={{margin: '1.5rem', padding: '1rem', color:'#1842B6', boxShadow: "0 0 60px rgba(0, 0, 0, .2)"}}>
                        <Typography variant="h5" align="center" className={classes.padding8}>
                            Compound Wall
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <Paper style={{margin: '1.5rem', padding: '1rem', color:'#1842B6', boxShadow: "0 0 60px rgba(0, 0, 0, .2)"}}>
                        <Typography variant="h5" align="center" className={classes.padding8}>
                            Park Facility
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>

    );
}

export default DevelopmentWorks

const customStyles = makeStyles({
    navBar: {
        backgroundColor: "#26d48c",
        boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 4%)",
    },
    title: {
        flexGrow: 1,
        color: "#FFF",
        fontWeight: 500,
    },
    margin8: {
        margin: "10px 0",
    },
    fullWidth: {
        width: "100vw"
    },
    postBtn: {
        minWidth: 120,
        padding: "8px 20px",
        margin: "10px 0px",
        boxShadow: "none",
        borderRadius: 10,
        color: "#f7f7f7",
        fontWeight: 600,

        '&:hover': {
            boxShadow: "none",
        },
    },
    loginRoot: {
        flexGrow: 1,
        height: "100vh",
        backgroundColor: '#F7F7F7',
    },
    gridSize: {
        display: "flex",
        justifyContent: "center",
    },
    cardSize: {
        maxWidth: "450px",
        padding: 40,
        margin: "auto",
        borderRadius: 10,
        boxShadow: "none",
    },
    cardTitle: {
        color: "#26d48c",
        textAlign: "center",
        fontWeight: 600
    }
})

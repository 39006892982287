export const roles = [
    {
        name: 'Director',
        slug: 'director',
    },
    {
        name: 'Partner',
        slug: 'partner',
    },
    {
        name: 'Investor',
        slug: 'investor',
    },
]

export const introducers = [
    {
        name: 'Gunasekaran K',
        slug: 'guna',
    },
    {
        name: 'Mohan K',
        slug: 'mohan',
    },
    {
        name: 'Senthilkumar',
        slug: 'senthil',
    },
    {
        name: 'Kulanthaivel',
        slug: 'kv',
    },
]

export const mainMenus = {
    menu: 'Main Board',
    sub: [
        {
            menu: 'Dashboard',
            link: '/dashboard',
        },
        {
            menu: 'Projects',
            link: '/dashboard/projects',
        },
        // {
        //     menu: 'Available Fund',
        //     link: '/dashboard/funds',
        // },
        {
            menu: 'Investments',
            link: '/dashboard/investments',
        },
    ],
}

export const teamMenus = {
    menu: 'Team Members',
    sub: [
        {
            menu: 'Partners',
            link: '/dashboard/team/partners',
        },
        {
            menu: 'Investors',
            link: '/dashboard/team/investors',
        },
        {
            menu: 'Employees',
            link: '/dashboard/team/employees',
        },
    ],
}

export const otherMenus = {
    menu: 'Others',
    sub: [
        {
            menu: 'Customers',
            link: '/dashboard/customers',
        },
        {
            menu: 'Expenses',
            link: '/dashboard/expenses',
        },
        {
            menu: 'Documents',
            link: '/dashboard/documents',
        },
        // {
        //     menu: 'My Profile',
        //     link: '/dashboard/profile',
        // },
    ],
}

export const footerLeftMenus = {
    menu: 'Blog',
    sub: [
        {
            menu: 'Development Works',
            link: '/blog',
        },
    ],
}

export const footerMidMenus = {
    menu: 'Useful Links',
    sub: [
        {
            menu: 'About',
            link: '/about',
        },
        {
            menu: 'Admin Login',
            link: '/login',
        },
    ],
}

export const footerRightMenus = {
    menu: 'Other Links',
    sub: [
        {
            menu: 'Privacy Policy',
            link: '/privacy-policy',
        },
    ],
}

export const investorsHeader = ['Id', 'Name', 'Occupation', 'Investment', 'Contact', 'Place', 'Role', 'Address']

export const customersHeader = ['Customer Id', 'Customer Name', 'Looking To', 'Contact', 'Email Address']

export const investmentsHeader = ['Investor Id', 'Investor Name', 'Investment Amount', 'Plan', 'Actions']

export const projectsHeader = ['Id', 'Name', 'Location', 'Size', 'Value', 'Returns', 'Status', 'Actions']

export const teamsHeader = ['Member Id', 'Member Name', 'Introducer', 'Role', 'Actions']

export const accountsHeader = ['Data Id', 'Amount', 'From', 'To', 'Type', 'Actions']

export const expensesHeader = ['Id', 'Expenses', 'Amount', 'Mode', 'Given By', 'Date', 'Remarks']

export const dashboardCard = [
    {
        name: 'Total Projects',
        value: 2,
    },
    {
        name: 'Total Investments',
        value: 12500000,
    },
    {
        name: 'Total Team Members',
        value: 5,
    },
    {
        name: 'Total Cents',
        value: 68,
    },
]

export const knImages = [
    {
        url: 'https://drive.google.com/uc?export=view&id=1Fpu4bcMFmEqcl55rqT8IDtJlVoXPkRH3',
    },
    {
        url: 'https://drive.google.com/uc?export=view&id=1Jo-zbPbsoFJo1KJHW5Lri2qAw7xOOoUa',
    },
    {
        url: 'https://drive.google.com/uc?export=view&id=11aS5HHquKkFQbGBm1nbEIE1sij6T_2Pu',
    },
    {
        url: 'https://drive.google.com/uc?export=view&id=1oueMRKfb-eR7tlP35PlvnWrd7Pmyk74d',
    },
    {
        url: 'https://drive.google.com/uc?export=view&id=1qz3v6zIU0t82vg1Q-n8OqI4DjBeQGQ7B',
    },
    {
        url: 'https://drive.google.com/uc?export=view&id=1Yk01TW3RKp3SCoXYWKJk0fFgc8aGA2W5',
    },
    {
        url: 'https://drive.google.com/uc?export=view&id=1WA9HXZAWsB9wL9EATTeNODDDsHr4rms_',
    },
    {
        url: 'https://drive.google.com/uc?export=view&id=1Yk01TW3RKp3SCoXYWKJk0fFgc8aGA2W5',
    },
]

export const BDImages = [
    {
        url: 'https://drive.google.com/uc?export=view&id=1Fpu4bcMFmEqcl55rqT8IDtJlVoXPkRH3',
    },
    {
        url: 'https://drive.google.com/file/d/1h6U8vzegd_8hPVIalWI3gl_XeU82PzPx/view?usp=sharing',
    }
]

export const plImg = [
    {
        url: 'https://drive.google.com/uc?export=view&id=1h6U8vzegd_8hPVIalWI3gl_XeU82PzPx',
        name: 'Paramathi Velur'
    },
    {
        url: 'https://drive.google.com/uc?export=view&id=1VBBOASbXPkG1K6yOkYNzyLVb8o6IR0ww',
        name: 'Mohanur'
    },
    {
        url: 'https://drive.google.com/uc?export=view&id=1s541QXZi0RZCzgrETafrLnY-NgpDCNlf',
        name: 'Namakkal'
    },
    {
        url: 'https://drive.google.com/uc?export=view&id=1RPElp-tL5kP6DCUb-vhtwCikty7IQhTo',
        name: 'KabilarMalai'
    },
]

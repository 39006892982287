import React from "react";
import {makeStyles} from "@mui/styles";
import {
    List,
    ListItem,
    ListItemText,
    Drawer
} from "@mui/material";
import {Link} from "react-router-dom";
import clsx from "clsx";


const DrawerComponent = ({open, toggleDrawerHandler}) => {
    const classes = customStyles();
    const menus = [
        {
            link: "/",
            name: "Home",
            variant: "default",
            button: false,
        },
        {
            link: "/properties",
            name: "Find Property",
            variant: "default",
            button: false,
        },
        // {
        //     link: "/property-services",
        //     name: "Property Services",
        //     variant: "default",
        //     button: false,
        // },
        // {
        //     link: "/loans",
        //     name: "Loans",
        //     variant: "default",
        //     button: false,
        // },
        {
            link: "/approval-process",
            name: "Approval Process",
            variant: "default",
            button: false,
        },
        {
            link: "/contact-us",
            name: "Contact Us",
            variant: "default",
            button: false,
        },
    ];
    return (
        <Drawer open={open} onClose={toggleDrawerHandler}>
            <div
                style={{width: 250}}
                role="presentation"
                onClick={toggleDrawerHandler}
                onKeyDown={toggleDrawerHandler}
            >
                <List>
                    {menus.map((value) => (
                        <ListItem button key={value.name}>
                            <Link to={value.link} className={clsx(classes.textDecoration)} style={{color: 'rgb(32, 34, 52)'}}><ListItemText primary={value.name} /></Link>
                        </ListItem>
                    ))}
                </List>
            </div>
        </Drawer>
    );
}

export default DrawerComponent;

const customStyles = makeStyles({
    textDecoration: {
        textDecoration: 'none'
    }
})

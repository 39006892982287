const actions = {
    GET_PROPERTIES_START: 'GET_PROPERTIES_START',
    GET_PROPERTIES_SUCCESS: 'GET_PROPERTIES_SUCCESS',
    GET_PROPERTIES_FAILURE: 'GET_PROPERTIES_FAILURE',
    CREATE_PROPERTIES_START: 'CREATE_PROPERTIES_START',
    CREATE_PROPERTIES_SUCCESS: 'CREATE_PROPERTIES_SUCCESS',
    CREATE_PROPERTIES_FAILURE: 'CREATE_PROPERTIES_FAILURE',
    GET_PROPERTY_DETAILS_START: 'GET_PROPERTY_DETAILS_START',
    GET_PROPERTY_DETAILS_SUCCESS: 'GET_PROPERTY_DETAILS_SUCCESS',
    GET_PROPERTY_DETAILS_FAILURE: 'GET_PROPERTY_DETAILS_FAILURE'
};

export default actions;

import { all } from 'redux-saga/effects';
import authSaga from './auth/sagas';
import propertiesSaga from './main/properties/sagas';

export default function* rootSaga() {
  yield all([
      authSaga(),
      propertiesSaga(),
  ]);
}

import actions from './actions';

const initState = {
    loginLoader: false,
    isLoggedIn: false,
    loggedInUser: '',
    loggedInUserProfileId: '',
    loggedInUserProfileDetails: {},
    registerLoader: false,
    changePasswordLoader: false,
    businessDetails: {}
};

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_START: {
            return {
                ...state,
                loginLoader: true,
            };
        }
        case actions.LOGIN_SUCCESS: {
            return {
                ...state,
                loginLoader: false,
                isLoggedIn: true,
                // loggedInUser: action.payload.uid,
                // loggedInUserProfileId: action.authDetails.userId,
                // loggedInUserProfileDetails: action.authDetails[0]
            };
        }
        case actions.LOGIN_FAILURE: {
            return {
                ...state,
                loginLoader: false,
            };
        }
        case actions.REGISTER_START: {
            return {
                ...state,
                registerLoader: true,
            };
        }
        case actions.REGISTER_SUCCESS: {
            return {
                ...state,
                register: action.payload,
                registerLoader: false,
            };
        }
        case actions.REGISTER_FAILURE: {
            return {
                ...state,
                registerLoader: false,
            };
        }
        case actions.CHANGE_PASSWORD_START: {
            return {
                ...state,
                religiousDetailsLoader: true,
            };
        }
        case actions.CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                religiousDetails: action.payload,
                religiousDetailsLoader: false,
            };
        }
        case actions.CHANGE_PASSWORD_FAILURE: {
            return {
                ...state,
                religiousDetailsLoader: false,
            };
        }
        case actions.LOGOUT_START: {
            return {
                ...state,
                logoutLoader: true,
            };
        }
        case actions.LOGOUT_SUCCESS: {
            return {
                ...state,
                isLoggedOut: true,
                logoutLoader: false,
            };
        }
        case actions.LOGOUT_FAILURE: {
            return {
                ...state,
                logoutLoader: false,
            };
        }
        case actions.GET_BUSINESS_DETAILS_START: {
            return {
                ...state,
                businessDetailsLoader: true,
            };
        }
        case actions.GET_BUSINESS_DETAILS_SUCCESS: {
            return {
                ...state,
                businessDetailsLoader: false,
                businessDetails: action.payload[0],
            };
        }
        case actions.GET_BUSINESS_DETAILS_FAILURE: {
            return {
                ...state,
                businessDetailsLoader: false,
            };
        }
        default:
            return state;
    }
}

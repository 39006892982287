import {Grid, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import primelocation from '../../../assets/images/prime-location.png';
import legal from '../../../assets/images/legal-opinion.png';
import approval from '../../../assets/images/approval.png';

const ChooseUs = () => {
    const classes = customStyles();
    return (
        <div style={{backgroundColor: '#F7F7F7', padding: 20}}>
            <Typography variant='h4' style={{color: '#0c5aa5', padding: '1.5rem', textAlign: 'center', fontWeight: 500}}>Why Choose Us?</Typography>
            <Grid container style={{backgroundColor: '#F7F7F7', padding: 20}}>
                <Grid item xs={12} md={6} lg={4} className={classes.flexCenter}>
                    <div>
                        <div style={{padding: '20px', backgroundColor: '#AFE1AF', textAlign: 'center', borderRadius: '20px'}}><img src={primelocation} style={{width: '100px', height: '100px'}} /></div>
                        <Typography variant='h6' style={{fontSize: '24px', fontWeight: 500, padding: '20px 0 0 0', textAlign: 'center'}}>Prime Location</Typography>
                        <Typography variant='h6' style={{fontSize: '16px', fontWeight: 400, padding: '10px 0 0 0', textAlign: 'center'}}>We build plots close to cities. All our plots are set up in Paramathi Velur, Mohanur and Namakkal surroundings</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className={classes.flexCenter}>
                    <div>
                        <div style={{padding: '20px', backgroundColor: '#AFE1AF', textAlign: 'center', borderRadius: '20px'}}><img src={legal} style={{width: '100px', height: '100px'}} /></div>
                        <Typography variant='h6' style={{fontSize: '24px', fontWeight: 500, padding: '20px 0 0 0', textAlign: 'center'}}>Legal Opinion</Typography>
                        <Typography variant='h6' style={{fontSize: '16px', fontWeight: 400, padding: '10px 0 0 0', textAlign: 'center'}}>We build plots close to cities. All our plots are set up in Paramathi Velur, Mohanur and Namakkal surroundings</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className={classes.flexCenter}>
                    <div>
                        <div style={{padding: '20px', backgroundColor: '#AFE1AF', textAlign: 'center', borderRadius: '20px'}}><img src={approval} style={{width: '100px', height: '100px'}} /></div>
                        <Typography variant='h6' style={{fontSize: '24px', fontWeight: 500, padding: '20px 0 0 0', textAlign: 'center'}}>DTCP & RERA Approved</Typography>
                        <Typography variant='h6' style={{fontSize: '16px', fontWeight: 400, padding: '10px 0 0 0', textAlign: 'center'}}>We build plots close to cities. All our plots are set up in Paramathi Velur, Mohanur and Namakkal surroundings</Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default ChooseUs;

const customStyles = makeStyles({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0 20px',
    },
    cardTitle: {
        color: '#FFFFFFCC',
        margin: '20px 20px',
        textAlign: 'center',
        fontWeight: 500,
    },
    primaryTypo: {
        padding: '2px 10px',
        fontWeight: 600
    },
})

import {Grid, Typography} from '@mui/material';
import Navbar from '../Header/Navbar';
import {Breadcrumb} from 'antd';
import {Link} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import Footer from '../Footer';

const ContactUs = () => {
    const classes = customStyles()
    return (
        <>
            <Navbar />
            <Typography variant='h4' className={classes.cardTitle}> Contact Us </Typography>
            <Breadcrumb style={{marginLeft: 20, color: '#26d48c', fontSize: '18px', padding: 20}}>
                <Breadcrumb.Item><Link to={'/'}> Home </Link></Breadcrumb.Item>
                <Breadcrumb.Item> Contact Us </Breadcrumb.Item>
            </Breadcrumb>
            <div style={{backgroundColor: "#FFF", padding: 30}}>
                <Grid container className={classes.flexCenter}>
                    <Grid item lg={6} md={6} sm={12}>
                        <div style={{padding: 20, borderLeft: '6px solid red', backgroundColor: '#FFF'}}>
                            <Typography variant="h6" style={{fontSize: "18px", color: "#0c5aa5", padding: 5}}>Office Address</Typography>
                            <Typography variant="h5" style={{fontSize: "26px", color: "#000", padding: 5, fontWeight: 500}}>VIP City Properties</Typography>
                            <Typography variant="h5" style={{fontSize: "22px", padding: 5}}>College Road,</Typography>
                            <Typography variant="h5" style={{fontSize: "22px", padding: 5}}>Paramathi Velur Taluk,</Typography>
                            <Typography variant="h5" style={{fontSize: "22px", padding: 5}}>Namakkal District - 638 182</Typography>
                            <Typography variant="h5" style={{fontSize: "22px", padding: 5, fontWeight: 500}}>Cell: 76676 23456</Typography>
                            <Typography variant="h5" style={{fontSize: "22px", padding: 5, fontWeight: 500}}>Cell: 96779 92240</Typography>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} style={{paddingLeft: 20}}>
                        {/* <EnquiryForm /> */}
                        {/* <div style={{height: '375px', width: '375px', }}> */}
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d851.2285932761991!2d78.00560375763594!3d11.114421041709688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1698563735301!5m2!1sen!2sin" width="550" height="350" styles="border:1;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        {/* </div> */}
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    );
};

export default ContactUs;

const customStyles = makeStyles({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    cardTitle: {
        color: '#0c5aa5',
        padding: "50px 0",
        textAlign: "center",
        fontWeight: 500,
        backgroundColor: '#F7F7F7'
    },
    profileCardStyle: {
        // margin: "12px 20px",
        // width: '100%',
        borderRadius: 10,
        // border: "1px solid #00a03a",
        boxShadow: "none",
        backgroundColor: "#FFF7E7",
    },
    margin8a: {
        margin: "10px",
    }, 
    textDecoration: {
        textDecoration: "none"
    },
    media: {
        height: 250,
        // width: 250,
        // paddingTop: '56.25%',
    },
    viewProfileBtn: {
        minWidth: 120,
        padding: "8px 20px",
        margin: "0 0 25px 5px",
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: "#00a03a",
        color: "#f7f7f7",
        border: "1px solid #00a03a",
        fontWeight: 600,

        '&:hover': {
            backgroundColor: "#FFF7E7",
            color: "#000000",
            border: "1px solid #FFF7E7",
            boxShadow: "none",
        },
    },
})

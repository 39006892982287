const actions = {
    REGISTER_START:'REGISTER_START',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',
    LOGIN_START:'LOGIN_START',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    CUSTOMER_REGISTRATION_START:'CUSTOMER_REGISTRATION_START',
    CUSTOMER_REGISTRATION_SUCCESS: 'CUSTOMER_REGISTRATION_SUCCESS',
    CUSTOMER_REGISTRATION_FAILURE: 'CUSTOMER_REGISTRATION_FAILURE',
    CUSTOMER_LOGIN_START:'CUSTOMER_LOGIN_START',
    CUSTOMER_LOGIN_SUCCESS: 'CUSTOMER_LOGIN_SUCCESS',
    CUSTOMER_LOGIN_FAILURE: 'CUSTOMER_LOGIN_FAILURE',
    CHANGE_PASSWORD_START: 'CHANGE_PASSWORD_START',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
    LOGOUT_START:'LOGOUT_START',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'LOGOUT_FAILURE',
};

export default actions;

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Card, CardContent, CardMedia, Grid, Typography} from '@mui/material';
import {Breadcrumb} from 'antd';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import Scroll from 'react-scroll';
import clsx from 'clsx';
import actions from '../../../redux/main/properties/actions';
import avatar from '../../../assets/images/avatar.png';
import Navbar from '../../Header/Navbar';
import Footer from '../../Footer';


const Properties = () => {
    const {properties} = useSelector(state => state.propertiesReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({type: actions.GET_PROPERTIES_START});
    }
    const loadProperty = (value) => {
        Scroll.animateScroll.scrollToTop();
        dispatch({type: actions.GET_PROPERTY_DETAILS_START, propertyId: value});
    };
    const classes = customStyles();
    console.log(properties)
    return(
        <>
            <Navbar />
            <Typography variant='h4' className={classes.cardTitle}> Find Property </Typography>
            <Breadcrumb style={{marginLeft: 20, color: '#26d48c', fontSize: '18px', padding: 20}}>
                <Breadcrumb.Item><Link to={'/'}> Home </Link></Breadcrumb.Item>
                <Breadcrumb.Item> Find Property </Breadcrumb.Item>
            </Breadcrumb>
            <Grid container className={classes.flexCenter}>
                    {properties.map((t) => (
                    <>
                        <Grid item xs={12} md={4}>
                            <Card className={clsx(classes.profileCardStyle, classes.margin8a)} key={t.username}>
                                <CardMedia
                                    className={classes.media}
                                    image={t.propertyImages[0].url}
                                    title={t.title}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Typography variant='subtitle1'>
                                Price: <span className={classes.primaryTypo}>{t.price}</span>
                            </Typography>
                            <Typography variant='subtitle1'>
                                Title: <span className={classes.primaryTypo}>{t.title}</span>
                            </Typography>
                            <Typography variant='subtitle1'>
                                Description: <span className={classes.primaryTypo}>{t.des}</span>
                            </Typography>
                            <Typography variant='subtitle1'>
                                Land Facing: <span className={classes.primaryTypo}>{t.facing}</span>
                            </Typography>
                            <Typography variant='subtitle1'>
                                Length: <span className={classes.primaryTypo}>{t.length}, Breadth: {t.breadth}</span>
                            </Typography>
                            <Typography variant='subtitle1'>
                                Location: <span className={classes.primaryTypo}>{t.location}</span>
                            </Typography>
                            <Link
                                to={`/properties/${t.id}`}
                                className={clsx(classes.margin8a, classes.textDecoration)}
                            >
                                <Button
                                    type='submit'
                                    color='primary'
                                    variant='contained'
                                    className={clsx(classes.viewProfileBtn)}
                                    onClick={() => loadProperty(t.id)}
                                >
                                    View Property
                                </Button>
                            </Link>
                        </Grid>
                    </>
                    ))}
                </Grid>
            <Footer />
        </>
    );
}

export default Properties;

const customStyles = makeStyles({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    cardTitle: {
        color: '#0c5aa5',
        padding: "50px 0",
        textAlign: "center",
        fontWeight: 500,
        backgroundColor: '#F7F7F7'
    },
    profileCardStyle: {
        // margin: "12px 20px",
        // width: '100%',
        borderRadius: 10,
        // border: "1px solid #00a03a",
        boxShadow: "none",
        backgroundColor: "#FFF7E7",
    },
    margin8a: {
        margin: "10px",
    }, 
    textDecoration: {
        textDecoration: "none"
    },
    media: {
        height: 250,
        // width: 250,
        // paddingTop: '56.25%',
    },
    primaryTypo: {
        padding: '2px 10px',
        fontWeight: 600
    },
    viewProfileBtn: {
        minWidth: 120,
        padding: "8px 20px",
        margin: "0 0 25px 5px",
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: "#00a03a",
        color: "#f7f7f7",
        border: "1px solid #00a03a",
        fontWeight: 600,

        '&:hover': {
            backgroundColor: "#FFF7E7",
            color: "#000000",
            border: "1px solid #FFF7E7",
            boxShadow: "none",
        },
    },
})

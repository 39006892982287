import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import clsx from 'clsx'
import {Hidden} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerComponent from "./DrawerComponent";


const Navbar = () => {
    const classes = customStyles();
    const [visible, setVisible] = useState(false);
    const toggleDrawer = () => {
        setVisible(false)
    };
    const handleMobileDrawerOpen = () => {
        setVisible(true)
    };
    const menus = [
        {
            link: "/",
            name: "Home",
            variant: "default",
            button: false,
        },
        {
            link: "/properties",
            name: "Find Property",
            variant: "default",
            button: false,
        },
        // {
        //     link: "/property-services",
        //     name: "Property Services",
        //     variant: "default",
        //     button: false,
        // },
        {
            link: "/gallery",
            name: "Project Gallery",
            variant: "default",
            button: false,
        },
        {
            link: "/approval-process",
            name: "Approval Process",
            variant: "default",
            button: false,
        },
        {
            link: "/contact-us",
            name: "Contact Us",
            variant: "default",
            button: false,
        },
        // {
        //     link: "/post-property",
        //     name: "Post Property",
        //     variant: "default",
        //     button: false,
        // },
        // {
        //     link: "/login",
        //     name: "Login",
        //     variant: "default",
        //     button: false,
        // },
    ];

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.navBar}>
                <Toolbar>
                    {/*<Avatar variant='square' alt="" src={image} className={classes.logo} />*/}
                    <Typography variant="h5" className={classes.title}>
                        VIP City Properties
                    </Typography>
                    <Hidden mdUp>
                        <IconButton
                            className={classes.menuButton}
                            onClick={handleMobileDrawerOpen}
                            aria-label="Open Navigation"
                        >
                            <MenuIcon color="primary" />
                        </IconButton>
                    </Hidden>
                    <Hidden smDown>
                        {menus.map(element => {
                            if (element.link) {
                                return (
                                    <Link
                                        key={element.name}
                                        to={element.link}
                                        className={clsx(classes.margin8a, classes.textDecoration)}
                                        // onClick={handleMobileDrawerClose}
                                    >
                                        <Button
                                            disableRipple
                                            variant={element.variant}
                                            className={element.button ? classes.postBtn : classes.navLink}
                                        >
                                            {element.name}
                                        </Button>
                                    </Link>
                                );
                            }
                            return (
                                <Button
                                    color="secondary"
                                    size="large"
                                    onClick={element.onClick}
                                    key={element.name}
                                >
                                    {element.name}
                                </Button>
                            );
                        })}
                    </Hidden>
                </Toolbar>
            </AppBar>
            <DrawerComponent
                open={visible}
                toggleDrawerHandler={toggleDrawer}
            />
        </div>
    );
}

export default Navbar

const customStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    navBar: {
        boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 4%)",
    },
    title: {
        flexGrow: 1,
        color: "#0c5aa5",
        fontWeight: 500,
    },
    menuButton: {
        borderRadius: 10,
        backgroundColor: "#f7f7f7",
        color: "#808080",
        fontSize: 10,

        '&:hover': {
            color: "#000",
            backgroundColor: "#f7f7f7",
        },
    },
    margin8a: {
        margin: "10px",
    },
    navLink: {
        color: "#808080",
        margin: 0,
        fontSize: "16px",
        fontWeight: 400,
        textDecoration: "none",
        textTransform: "capitalize",

        '&:hover': {
            color: "#006aff",
            backgroundColor: "inherit",
        }
    },
    textDecoration: {
        textDecoration: "none"
    },
})

import {Box, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {footerLeftMenus, footerMidMenus, footerRightMenus} from "../../helpers/constant";


const Footer = () => {
    return(
        <Box component="footer" style={{backgroundColor: "#1C1C1E", paddingBottom: 40}}>
            <div style={{backgroundColor: "#1C1C1E"}}>
            <Grid container>
                <Grid item xs={12} md={4.5}>
                    <div style={{padding: 10, margin: 30, marginTop: 30}}>
                        <Typography style={{fontSize: '22px', fontWeight: 500, color: '#FFF', margin: '0.5em'}}>VIP City Properties</Typography>
                        <div style={{paddingLeft: 10}}>
                            <Typography style={{fontSize: '18px', padding: 0, color: '#FFF'}}>We focus on delivering agricultural lands, plots, houses, commercial properties and so on all over Tamilnadu especially in Paramathi Velur, Mohanur and surroundings of Namakkal and Karur District</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={2}>
                    <div style={{padding: 10, margin: 30, marginTop: 30}}>
                        <Typography style={{fontSize: '20px', margin: '0.5em', color: '#999', fontWeight: 500}}>{footerLeftMenus.menu}</Typography>
                        <div style={{paddingLeft: 10}}>
                            {footerLeftMenus.sub.map(menus => (
                                <Typography style={{fontSize: '16px', padding: 5}}><Link to={menus.link} style={{color: '#FFF', textDecoration: 'none'}}>{menus.menu}</Link></Typography>
                            ))}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={2}>
                    <div style={{padding: 10, margin: 30, marginTop: 30}}>
                        <Typography style={{fontSize: '20px', margin: '0.5em', color: '#999', fontWeight: 500}}>{footerMidMenus.menu}</Typography>
                        <div style={{paddingLeft: 10}}>
                            {footerMidMenus.sub.map(menus => (
                                <Typography style={{fontSize: '16px', padding: 5}}><Link to={menus.link} style={{color: '#FFF', textDecoration: 'none'}}>{menus.menu}</Link></Typography>
                            ))}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={3.5}>
                    <div style={{padding: 10, margin: 30, marginTop: 30}}>
                        <Typography style={{fontSize: '20px', margin: '0.5em', color: '#999', fontWeight: 500}}>Office Address</Typography>
                        <div style={{paddingLeft: 10, color: "#FFF"}}>
                            <Typography variant="h5" style={{fontSize: "18px", padding: 5, fontWeight: 500}}>VIP City Properties</Typography>
                            <Typography variant="h5" style={{fontSize: "16px", padding: 5}}>College Road, Paramathi Velur Taluk,</Typography>
                            <Typography variant="h5" style={{fontSize: "16px", padding: 5}}>Namakkal District - 638 182</Typography>
                            <Typography variant="h5" style={{fontSize: "16px", padding: 5, fontWeight: 500}}>Cell: 76676 23456, 96779 92240</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
            <Typography
               variant="h6"
               align="center"
               style={{fontWeight: 400, color: '#999', fontSize: '16px'}}
            >
               We are one of the leading real estate development companies, headquartered in Paramathi Velur, Namakkal Dt | VIP City Properties | vipcityproperties.com | Privacy Policy | Terms & Conditions
            </Typography>
            <Typography
            variant="subtitle1"
            align="center"
            // className={classes.margin8}
            style={{color: '#999', fontSize: '16px', backgroundColor: '#1C1C1E'}}
        >
            {'Copyright © '}
            <Link href="https://www.vipcityproperties.com/">
                VIP City Properties
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
        </Box>
    );
}

export default Footer

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Card, CardContent, CardMedia, Grid, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import Scroll from 'react-scroll';
import clsx from 'clsx';
import actions from '../../../redux/main/properties/actions';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const FeaturedProperties = () => {
    const {properties} = useSelector(state => state.propertiesReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    },[]);
    const loadTasks = () => {
        dispatch({ type: actions.GET_PROPERTIES_START, limit: 10 });
    };
    const loadProperty = (value) => {
        Scroll.animateScroll.scrollToTop();
        dispatch({type: actions.GET_PROPERTY_DETAILS_START, propertyId: value});
    };
    const settings = {
        dots: true,
        arrows:true,
        autoplay: true,
        autoplaySpeed: 2500,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 775,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const classes = customStyles();
    return (
        <div style={{backgroundColor: '#fff', padding: 20}}>
            <Typography variant='h4' style={{color: '#0c5aa5', padding: '1.5rem', textAlign: 'center', fontWeight: 500}}>Featured Properties</Typography>
            <Grid container style={{backgroundColor: '#fff', padding: 10}}>
                <Grid item xs={12} className={classes.flexCenter}>
                    {properties.map((t) => (
                        <Grid item xs={12} md={3}>
                            <Card className={clsx(classes.profileCardStyle, classes.margin8a)} key={t.username}>
                                <CardMedia
                                    className={classes.media}
                                    image={t.propertyImages[0].url}
                                    title={t.title}
                                />
                                <CardContent>
                                    <Typography variant='subtitle1'>
                                        Price: <span className={classes.primaryTypo}>{t.price}</span>
                                    </Typography>
                                    <Typography variant='subtitle1'>
                                        Title: <span className={classes.primaryTypo}>{t.title}</span>
                                    </Typography>
                                    <Typography variant='subtitle1'>
                                        Land Facing: <span className={classes.primaryTypo}>{t.facing}</span>
                                    </Typography>
                                    <Typography variant='subtitle1'>
                                        Length: <span className={classes.primaryTypo}>{t.length}, Breadth: {t.breadth}</span>
                                    </Typography>
                                    <Typography variant='subtitle1'>
                                        Location: <span className={classes.primaryTypo}>{t.location}</span>
                                    </Typography>
                                </CardContent>
                                <Link
                                    to={`/properties/${t.id}`}
                                    className={clsx(classes.margin8a, classes.textDecoration)}
                                >
                                    <Button
                                        type='submit'
                                        color='primary'
                                        variant='contained'
                                        className={clsx(classes.viewProfileBtn)}
                                        onClick={() => loadProperty(t.id)}
                                    >
                                        View Property
                                    </Button>
                                </Link>
                            </Card>
                        </Grid>
                    ))}
                </Grid>            
            </Grid>
        </div>
    );
};

export default FeaturedProperties;

const customStyles = makeStyles({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    cardTitle: {
        // color: '#808080',
        margin: '20px 20px',
        textAlign: 'center',
        fontWeight: 400,
        padding: 20
    },
    profileCardStyle: {
        boxShadow: 'none',
    },
    margin8a: {
        margin: '10px',
    },
    media: {
        height: 250,
    },
    text: {
        fontSize: '18px',
        margin: 2,
        fontWeight: 400
    },
    enquiryHome: {
        flexGrow: 1,
        padding: '80px 0',
    },
    gridSize: {
        display: 'flex',
        justifyContent: 'center',
        // width: '49vw',
    },
    typo: {
        padding: '15px 15px',
        margin: '1rem',
        color: '#000000',
        backgroundColor: 'inherit',
        borderRadius: 10,
        boxShadow: 'none',
        // textAlign: 'center'
    },
    typeAlignCenter: {
        textAlign: 'center',
    },
    padding16: {
        padding: '15px 0',
    },
    primaryTypo: {
        padding: '2px 10px',
        fontWeight: 600
    },
    viewProfileBtn: {
        minWidth: 120,
        padding: "8px 20px",
        margin: "0 0 25px 5px",
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: "#00a03a",
        color: "#f7f7f7",
        border: "1px solid #00a03a",
        fontWeight: 600,

        '&:hover': {
            backgroundColor: "#999",
            color: "#000000",
            border: "1px solid #FFF7E7",
            boxShadow: "none",
        },
    },
})

import React from 'react';
import ImageUploading from 'react-images-uploading';
import {Button, Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import {ref, uploadBytes} from 'firebase/storage';
import {fbStorage} from '../../../helpers/firebaseClient';


const PostPropertyImages = () => {
    const [images, setImages] = React.useState([]);
    const maxNumber = 10;
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };
    const imageTypes = [
        {
            title: 'Property Photos'
        }
    ]
    const savePropertyImg = (files) => {
        console.log(files)
        {files.map(file => {
            const storageRef = ref(fbStorage, 'property-images/' + `${file.name}`)
            uploadBytes(storageRef, file).then((response) => {
                console.log('Uploaded property images', response);
            });
        })}
    }

    return (
        <div style={{backgroundColor: '#fff', padding: 32}}>
            <Typography style={{fontWeight: 500, fontSize: 24, marginBottom: 20}}>Property Photos</Typography>
            <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey='mkimages'
            >
                {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps
                  }) => (
                    <Grid container>
                        <Grid sm={12} md={6} lg={4} item>
                            <Button
                                style={{width: 250, height: 300, backgroundColor: isDragging ? '#FE1234' : '#F7F7F7'}}
                                onClick={onImageUpload}
                                {...dragProps}
                            >
                                Click or Drop here
                            </Button>
                            <br />
                            <Button style={{padding: 20}} onClick={onImageRemoveAll}>Remove all images</Button>
                        </Grid>
                        {imageList.map((image, index) => (
                            <Grid sm={12} md={6} lg={4} item>
                                <img src={image.mkimages} alt='' width='350' />
                                    <div style={{padding: 20}}>
                                        <Button onClick={() => onImageUpdate(index)}>Update</Button>
                                        <Button onClick={() => onImageRemove(index)}>Remove</Button>
                                    </div>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </ImageUploading>
            <Button variant={'contained'} onClick={() => savePropertyImg(images)}>Save Property Photos</Button>
        </div>
    );
}

export default PostPropertyImages

import { makeStyles } from "@mui/styles"
// import image from '../images/banner.jpg';
// import bannerMatrimony from '../images/matrimony/banner.jpg'

const customStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        borderRadius: 10,
        backgroundColor: "#f7f7f7",
        color: "#000000",
        fontSize: 10,

        '&:hover': {
            color: "#000000",
            backgroundColor: "#f7f7f7",
        },
    },
    title: {
        flexGrow: 1,
        color: "#000000",
    },
    navBar: {
        backgroundColor: "#fff",
        boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 4%)",
        marginBottom: 10
    },
    iconSize: {
        fontSize: 16,
    },
    bannerSize: {
        height: 500,
        // backgroundImage: `url(${image})`,

        '&::before': {
            content: '',
            backgroundColor: '#aaa',
        },
    },
    postBtn: {
        minWidth: 150,
        padding: "8px 20px",
        margin: "10px 0px",
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: "#00a03a",
        color: "#f7f7f7",
        // border: "1px solid #00a03a",
        fontWeight: 600,

        '&:hover': {
            backgroundColor: "#AFE1AF",
            color: "#000000",
            // border: "1px solid #FFF7E7",
            boxShadow: "none",
        },
    },
    textDecoration: {
        textDecoration: "none"
    },
    navLink: {
        color: "#00a03a",
        margin: 0,
        textDecoration: "none",

        '&:hover': {
            color: "#000000",
            backgroundColor: "inherit",
        }
    },
    links: {
        margin: "0 10px",
        color: "#f7f7f7",

        '&:hover': {
            color: "#FFF7E7",
        }
    },
    loginRoot: {
        flexGrow: 1,
        // height: "100vh",
        backgroundColor: "#f5faf8",
    },
    loginHome: {
        flexGrow: 1,
        // background: `url(${bannerMatrimony})`,
        padding: "80px 0",
    },
    loginHomeGrid: {
        display: "flex",
        justifyContent: "center",
        width: "50vw",
    },
    registerHome: {
        flexGrow: 1,
        // background: 'linear-gradient(to right, #fbbb00 20%, #000000 80%)',
        padding: "80px 0",
    },
    registerHomeBg: {
        backgroundColor: "#f5faf8",
    },
    gridSize: {
        display: "flex",
        justifyContent: "center",
        // width: "49vw",
    },
    flexCenter: {
        display: "flex",
        justifyContent: "center",
    },
    fullWidth: {
        width: "100vw"
    },
    cardBg: {
        backgroundColor: "#f5faf8",
        borderRadius: 10
    },
    cardSize: {
        width: "420px",
        padding: '1.5rem',
        margin: '1.5rem',
        borderRadius: 10,
        // border: theme.spacing(0.1, "solid", "#00a03a"),
        boxShadow: "none",
        // boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 4%)",
        // boxShadow: theme.spacing(0, 1, 2, 0, "#aaa"),
    },
    typo: {
        padding: "15px 15px",
        margin: "1rem",
        color: "#000000",
        backgroundColor: "inherit",
        borderRadius: 10,
        boxShadow: "none",
        // textAlign: "center"
    },
    inputImgFile: {

    },
    media: {
        height: 250,
        // width: 250,
        // paddingTop: '56.25%',
    },
    // maxWidth: {
    //     minWidth: 400,
    //     maxWidth: 500,
    // },
    typeAlignCenter: {
        textAlign: "center",
    },
    padding8: {
        padding: "8px 0",
    },
    padding16: {
        padding: "15px 0",
    },
    padding16a: {
        padding: "10px 16px",
    },
    padding1rem: {
        padding: "1rem",
    },
    padding40: {
        padding: "40px",
    },
    padding80: {
        padding: "80px",
    },
    margin8: {
        margin: "10px 0",
    },
    margin8a: {
        margin: "10px",
    },
    margin20tb: {
        margin: '20px 0',
    },
    margin25a: {
        margin: "0 25px",
    },
    margin60: {
        margin: "0 60px",
    },
    margin40: {
        margin: "40px",
    },
    backBtn: {
        height: 42,
        width: 42,
        color: "#EB252F",
        borderRadius: 50,
        padding: 10,
        // padding: "12px 10px 8px 10px",
        backgroundColor: "#FFF7E7",
    },
    inline: {
        display: "inline"
    },
    textAlignRight: {
        textAlign: "right"
    },
    color: {
        color: "#EB252F",
    },
    logo: {
        width: 200,
        margin: 10,
    },
    formControl: {
        margin: "10px 0",
        width: '100%',
    },
    simpleFooter: {
        padding: 40,
        backgroundColor: "#f5faf8",
        color: '#000000',
    },
    profileSlider: {
        // margin: "10px 40px 40px 40px",
        padding: 5,
    },
    chooseUs: {
        padding: 25,
    },
    viewProfileBtn: {
        minWidth: 120,
        padding: "8px 20px",
        margin: "0 0 25px 5px",
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: "#00a03a",
        color: "#f7f7f7",
        border: "1px solid #00a03a",
        fontWeight: 600,

        '&:hover': {
            backgroundColor: "#FFF7E7",
            color: "#000000",
            border: "1px solid #FFF7E7",
            boxShadow: "none",
        },
    },
    cardTitle: {
        color: '#000000',
        margin: "20px 20px",
        // padding: "25px 0",
        textAlign: "center",
        fontWeight: 500,
    },
    profileCardStyle: {
        // margin: "12px 20px",
        // width: '100%',
        borderRadius: 10,
        // border: "1px solid #00a03a",
        boxShadow: "none",
        backgroundColor: "#FFF7E7",
    },
    listingCardFilterGrid: {
        width: "28vw",
        padding: 30,
    },
    listingCardProfileGrid: {
        width: "68vw",
        padding: 30,
    },
    primaryTypo: {
        padding: '2px 10px',
        fontWeight: 600
    },
    paddingBottom20: {
        paddingBottom: "0 0 2.5 0"
    },
    paddingLeft20: {
        paddingLeft: "0 5 0 0"
    },
    leftFloat: {
        float: "left",
    },
    basicDetailPaper: {
        // margin: 10,
        padding: 10
    },
    bgColor: {
        backgroundColor: '#f5faf8'
    },
    whiteBg: {
        backgroundColor: '#fff'
    },
    darkOrange: {
        color: 'rgba(0, 0, 0, 0.87)'
    },
    PageTitle: {
        color: '#0c5aa5',
        padding: "50px 0",
        textAlign: "center",
        fontWeight: 500,
        backgroundColor: '#F7F7F7'
    },
});

export default customStyles;

import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import {Grid, Paper, TextField, Button, Typography, InputLabel, MenuItem, FormControl, Select} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import actions from '../../../redux/auth/actions';


const Register = () => {
    const [name, setName] = useState(null);
    const [lookingTo, setlookingTo] = useState(null);
    const [email, setEmailAddress] = useState(null);
    const [password, setPassword] = useState(null);
    const [contact, setContact] = useState(null);

    const dispatch = useDispatch();
    const handleSubmit = () =>{
        dispatch({ type: actions.CREATE_CUSTOMERS_START, payload:{name: name, lookingTo: lookingTo, email: email, password: password, contact: contact}});
    }
    const classes = customStyles();
    return(
        <>
            <AppBar position='static' className={classes.navBar}>
                <Toolbar>
                    <Typography variant='h5' className={clsx(classes.title)}>
                        Register
                    </Typography>                    
                </Toolbar>
            </AppBar> 
            <Grid container className={classes.loginRoot}>
                <Grid item className={clsx(classes.gridSize, classes.fullWidth)}>
                    <Paper className={classes.cardSize}>
                        <Grid align='center'>
                            <Typography variant='h5' style={{color: '#0c5aa5', fontWeight: 400, marginBottom: '20px', marginTop: 0}}>
                                Welcome to Exchange Hub!
                            </Typography>
                        </Grid>
                        <TextField
                            label='Name'
                            placeholder='Enter name'
                            fullWidth
                            required
                            className={classes.margin8}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <FormControl fullWidth className={classes.margin8}>
                            <InputLabel id='lookingTo'>Looking To</InputLabel>
                            <Select
                                labelId='lookingTo'
                                id='lookingTo'
                                value={lookingTo}
                                label='lookingTo'
                                onChange={(e) => setlookingTo(e.target.value)}
                            >
                                <MenuItem value={'Buy'}>Buy</MenuItem>
                                <MenuItem value={'Rent'}>Rent</MenuItem>
                                <MenuItem value={'Sell'}>Sell</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            label='Email Address'
                            placeholder='Enter email address'
                            fullWidth
                            required
                            className={classes.margin8}
                            onChange={(e) => setEmailAddress(e.target.value)}
                        />
                        <TextField
                            label='Phone Number'
                            placeholder='Enter phone number'
                            fullWidth
                            required
                            className={classes.margin8}
                            onChange={(e) => setContact(e.target.value)}
                        />
                        <TextField
                            label='Password'
                            placeholder='Enter password'
                            type='password'
                            fullWidth
                            required
                            className={classes.margin8}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            fullWidth
                            className={classes.postBtn}
                            onClick={() => handleSubmit()}
                        >
                            Create an Account
                        </Button>
                        <Typography className={clsx(classes.margin8)}>
                            <Link href='#' className={clsx(classes.textDecoration, classes.color)}>
                                Forgot password?
                            </Link>
                        </Typography>
                        <Typography className={classes.margin8}> Do you have an account?
                            <Link href='#' className={clsx(classes.margin8a, classes.textDecoration, classes.color)}>
                                Login to your account
                            </Link>
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default Register

const customStyles = makeStyles({
    navBar: {
        backgroundColor: '#26d48c',
        boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 4%)',
    },
    title: {
        flexGrow: 1,
        color: '#FFF',
        fontWeight: 400,
    },
    margin8: {
        margin: '10px 0',
    },
    fullWidth: {
        width: '100vw'
    },
    postBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '10px 0px',
        boxShadow: 'none',
        borderRadius: 10,
        color: '#f7f7f7',
        fontWeight: 600,

        '&:hover': {
            boxShadow: 'none',
        },
    },
    loginRoot: {
        flexGrow: 1,
        // height: '100vh',
        padding: 20,
        backgroundColor: '#F7F7F7',
    },
    gridSize: {
        display: 'flex',
        justifyContent: 'center',
    },
    cardSize: {
        maxWidth: '450px',
        padding: 40,
        margin: 'auto',
        borderRadius: 10,
        boxShadow: 'none',
    },
})

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBNwPR6S_F9lZaOJ0zmBTTP7nglnAHfnFg",
  authDomain: "gm-real-estate.firebaseapp.com",
  projectId: "gm-real-estate",
  storageBucket: "gm-real-estate.appspot.com",
  messagingSenderId: "1009546634880",
  appId: "1:1009546634880:web:14c37355b494bb804de865",
  measurementId: "G-BWF5BHT33Z"
};

// Initialize Firebase
export const firebaseInit = initializeApp(firebaseConfig);
export const fbFirestore = getFirestore();
export const fbStorage = getStorage(firebaseInit);

import { useEffect } from 'react';
import {Grid, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {Breadcrumb} from 'antd';
import Slider from 'react-slick';
// import ImageScrollbar from '../../components/ImageScrollbar';
import actions from '../../../redux/main/properties/actions';
import Navbar from '../../Header/Navbar';
import {knImages} from '../../../helpers/constant';
import Footer from '../../Footer/index';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const PropertyDetails = (props) => {
    const {property} = props
    const propertyId = property.match.params.propertyId
    const {propertyDetails} = useSelector(state => state.propertiesReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {     
        dispatch({type: actions.GET_PROPERTY_DETAILS_START, propertyId: propertyId});

    };
    const settings = {
        // width: '3000px',
        dots: true,
        arrows:true,
        autoplay: true,
        autoplaySpeed: 2500,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 775,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const classes = customStyles();
    return (
        <>
            <Navbar />
            <div>
                <Grid container className={classes.flexCenter}>
                    <Grid item lg={12} md={12} sm={12} style={{padding: 20}}>
                        <Breadcrumb style={{
                            marginLeft: 20,
                            color: '#151B30',
                            fontSize: '18px'
                        }}>
                            <Breadcrumb.Item><Link to={'/properties'}> Properties </Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{propertyId}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div style={{margin: 10, padding: 10}}>
                            <Typography variant='h5' style={{fontSize: '20px', fontWeight: 500, padding: '10px', backgroundColor: '#F0F8FF'}}>{'Property Details'}</Typography>
                            <div style={{padding: '1rem 2rem'}}>
                                <Slider {...settings}>
                                    {knImages.map(ph => <div><img style={{padding: '20px', width: 375, height: 375}} src={ph.url} alt='drive image'/></div>)}
                                </Slider>
                                {/* <Slider {...settings}>
                                    {propertyDetails.propertyImages.map(ph => <div><img style={{padding: '20px', width: 375, height: 375}} src={ph.url} /></div>)}
                                </Slider> */}
                            </div>
                            <Typography variant='h5' style={{fontSize: '24px', fontWeight: 600, padding: '10px 0', color: '#1842b6'}}>₹ {propertyDetails.price}</Typography>
                            <Typography variant='h6'>Title : <span className={classes.primaryTypo}>{propertyDetails.title}</span></Typography>
                            <Typography variant='h6'>Type :  <span className={classes.primaryTypo}>{propertyDetails.type}</span></Typography>
                            <Typography variant='h6'>Description : <span className={classes.primaryTypo}>{propertyDetails.description}</span></Typography>
                            <Typography variant='h6'>Total Area : <span className={classes.primaryTypo}>{propertyDetails.area}</span></Typography>
                            <Typography variant='h6'>Land Facing : <span className={classes.primaryTypo}>{propertyDetails.facing}</span></Typography>
                            <Typography variant='h6'>Length : <span className={classes.primaryTypo}>{propertyDetails.length}</span></Typography>
                            <Typography variant='h6'>Breadth : <span className={classes.primaryTypo}>{propertyDetails.breadth}</span></Typography>
                            <Typography variant='h6'>District :  <span className={classes.primaryTypo}>{propertyDetails.district}</span></Typography>
                            <Typography variant='h6'>Landmark :  <span className={classes.primaryTypo}>{propertyDetails.landmark}</span></Typography>
                            <Typography variant='h6'>Location :  <span className={classes.primaryTypo}>{propertyDetails.location}</span></Typography>
                            <Typography variant='h6'>Address : <span className={classes.primaryTypo}>{propertyDetails.address}</span></Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    )
};

const customStyles = makeStyles({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    cardTitle: {
        color: '#FFFFFFCC',
        margin: '20px 20px',
        textAlign: 'center',
        fontWeight: 500,
    },
    primaryTypo: {
        padding: '2px 10px',
        fontWeight: 600
    },
})

export default PropertyDetails;

import actions from './actions';

const initState = {
    loader: false,
    properties: [],
    propertyDetails: [],
};

export default function mainReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_PROPERTIES_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_PROPERTIES_SUCCESS: {
            return {
                ...state,
                loader: false,
                properties: action.payload,
            };
        }
        case actions.GET_PROPERTIES_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_PROPERTY_DETAILS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_PROPERTY_DETAILS_SUCCESS: {
            return {
                ...state,
                loader: false,
                propertyDetails: action.payload,
            };
        }
        case actions.GET_PROPERTY_DETAILS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.CREATE_PROPERTIES_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.CREATE_PROPERTIES_SUCCESS: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.CREATE_PROPERTIES_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        
        default:
            return state;
    }
}

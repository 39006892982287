import {createTheme} from '@mui/material';

const customTheme = createTheme()

const theme = createTheme(customTheme,{
  palette: {
    primary: {
      light: '#728FCE',
      main: '#4865A0',
      dark: '#202075',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: 'white',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '100%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '10px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#00000099',
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #E0E0E0',
          borderRadius: 10
        }
      }
    }
  },
  typography: {
    fontFamily: [
      'Arial',
      'sans-serif',
    ],
  },
  overrides: {
    MuiFilledInput: {
      underline: {
        borderRadius: 10,
        borderBottom: 'none',

        '&:hover': {
          '&:before': {
            borderBottom: 'none',
          },
        },

        '&:before': {
          borderBottom: 'none',
        },

        '&:after': {
          borderBottom: 'none',
        },
      },
    },
    MuiAvatar: {
      root: {
        height: 200,
        width: 200,
        margin: '0 40 0 0',
      },
    },
    MuiGrid: {
      root: {
        marginTop: 0,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
      elevation1: {
        boxShadow: 'none',
        padding: '20',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
})

export default theme;

import Typography from '@mui/material/Typography';
import Navbar from '../Header/Navbar';
import {Breadcrumb} from 'antd';
import {Link} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import DTCPImg from '../../assets/images/dtcp-approval-services.jpg';
import Footer from '../Footer';

const Approval = () => {
    const classes = customStyles()
    return (
        <>
            <Navbar />
            <Typography variant='h4' className={classes.cardTitle}> Approval Process </Typography>
            <Breadcrumb style={{marginLeft: 20, color: '#26d48c', fontSize: '18px', padding: 20}}>
                <Breadcrumb.Item><Link to={'/'}> Home </Link></Breadcrumb.Item>
                <Breadcrumb.Item> Approval Process </Breadcrumb.Item>
            </Breadcrumb>
            <div style={{backgroundColor: '#FFF', padding: 20}}>
                {/* <Typography variant='h4' style={{color: '#0c5aa5', padding: '1.5rem', textAlign: 'center', fontWeight: 500}}>DTCP Layout Approval Application Process</Typography> */}
                <div style={{textAlign: 'center'}}><img src={DTCPImg} /></div>
                <div style={{padding: '1rem 2rem'}}>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>This article emphasis the application procedures involved to get DTCP layout approval in Tamilnadu. It provides step by step guide for DTCP layout approval process.</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Directorate of Town & Country Planning (DTCP), the authority to approve real estate land development in Tamil Nadu other than Chennai Metropolitan Area where Chennai Metropolitan Development Authority (CMDA) approves all type of layouts for residential, institutional & industrial developments. DTCP is established under the Tamil Nadu Town & Country Planning Act, 1971 to regulate planning & urban development in the state. This article emphasis the application procedures involved to get DTCP layout approval in Tamilnadu.</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Real estate developers should get Planning Permission (PP) from DTCP for layouts above 1 hectare for properties falling in Corporation, Municipality and Town Panchayat, and above 2 hectares in respect of rural areas. Local Planning Authorities (LPAs) have the power to give permissions for small-scale developments.</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>The Planning Permission is issued by the Planning Department of DTCP or designated authority with the powers delegated to approve certain development for its specified use & development size.</Typography>
                    <br />
                    <Typography variant='h6' style={{fontSize: '28px', fontWeight: 600}}>DTCP Layout Approval Application Process</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Step-1: The online application must be submitted with all requisite documents as prescribed. The online system shall not accept the application unless all such documents are submitted.</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>An exhaustive list of documents which needs to be submitted during online application is as listed below:</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Site plan, depicting width and status of roads, building setbacks and parking areas Copy of survey sketch/copy of village plan/copy of field measurement/book of survey number duly showing the proposed site. Extract of masterplan/indicative land use plan, showing the proposed site. 
                    Detailed plan showing existing features such as residential colonies/educational/medical/religious institutions within a radius of 500 metre from the proposed site.
                    Certificate from local authority (commissioner/panchayat secretary) showing width and nature/status of access roads. Registered ownership documents for the site, attested by a gazetted officer, showing the registered documents, survey number, extent, and schedule of boundaries. Land conversion certificate issued by the revenue authority. 
                    Various No Objection Certificates (~15 NOCs) wherever necessary from the concern department such as Revenue, Irrigation, Pollution Control Board, Forest, Roadways, Tamil Nadu Housing Board, Slum Clearance Board & Environment Clearance etc.</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Step-2: The local body to scrutiny and forward the application to the concerned Composite Local Planning Authorities (CLPA) / New Town Development Authorities (NTDA)/ Regional Office (RO) of Town and Country Planning with its remarks.</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Step-3: The technical section at the CLPA/ NTDA/ RO will scrutiny the application with reference to the norms and parameters.</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Step-4: The proposals will be forwarded to the office of DTCP along with site inspection report, copy extracts of Master Plan and Detailed Development Plan and specific recommendation & resolution.</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Step-5: After scrutiny by the technical section at the DTCP’s office, Director shall approve the road pattern for the layout based on the technical drawing submitted. Approval decision will be taken by the JD/DD/MS. It may be for approval, rejection or return or calling for additional particulars.</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Step-6: On approval of road pattern, the applicant to handover the road and Open Space Reservation (OSR) to respective local body.</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Step-7: The Centage charges and Development charges would be calculated, and demand letter will be sent to the applicant to remit the same</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Step-8: On receipt of demand payment by the applicant, fair copy of the technically approved layout will be prepared, numbered and due entries will be made in the layout register.</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Step-9: Three sets of technically approved layout will be dispatched to the LPA / NTDA along with the proceedings order. Of which, LPA / NTDA will send 2 sets of technically approved layout will be sent to the local body.</Typography>
                    <Typography variant='h6' style={{fontSize: '24px', fontWeight: 400}}>Step-10: Local body will accord final approval. Final approval for layout will be accorded by the local body only after the applicant provides all the infrastructural facilities like roads, water supply, drainage and streetlights and hands them over to the concerned local bodies by prescribed deed duly registered after either duly forming the roads/streets 
                    OR after paying the cost of road/street formation to the local body. On approval of the layout, the local body will intimate the concerned Sub-Registrar that the documents be registered. Tentative timeline for DTCP layout approval in Tamilnadu (in case of no shortfalls) is expected to be within 4 to 6 Months from the date of application. Average cost to obtain all necessary development approvals/ NOCs for residential plotted development is about INR 50-70 per sqft. 
                    This is based on the location, size, and other physical attributes of the property.</Typography>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Approval;

const customStyles = makeStyles({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    cardTitle: {
        color: '#0c5aa5',
        padding: "50px 0",
        textAlign: "center",
        fontWeight: 500,
        backgroundColor: '#F7F7F7'
    },
    profileCardStyle: {
        // margin: "12px 20px",
        // width: '100%',
        borderRadius: 10,
        // border: "1px solid #00a03a",
        boxShadow: "none",
        backgroundColor: "#FFF7E7",
    },
    margin8a: {
        margin: "10px",
    }, 
    textDecoration: {
        textDecoration: "none"
    },
    media: {
        height: 250,
        // width: 250,
        // paddingTop: '56.25%',
    },
    viewProfileBtn: {
        minWidth: 120,
        padding: "8px 20px",
        margin: "0 0 25px 5px",
        boxShadow: "none",
        borderRadius: 10,
        backgroundColor: "#00a03a",
        color: "#f7f7f7",
        border: "1px solid #00a03a",
        fontWeight: 600,

        '&:hover': {
            backgroundColor: "#FFF7E7",
            color: "#000000",
            border: "1px solid #FFF7E7",
            boxShadow: "none",
        },
    },
})
